<template>
    <div class="p-grid crud-demo">
        <Loader v-model="loading" />
        <div class="p-col-12">
            <div class="card">
                <HelperDialog v-model="userHelper" header="Buscar Usuario" :headers="userHeaders" :rows="users" @selected="selectUser" />
                <Panel header="Administrador de Requisiciones">
                    <BasicFormToolbar v-if="!view"  @list="openTable"
                        :actions="['list']" />
                    <div class="grid formgrid p-fluid">
                        <div class="col-12">
                            <Fieldset :toggleable="true" legend="Filtros">
                                <div class="p-fluid formgrid grid">
                                    <FormCalendar :readonly="true"
                                        wrapperClass="field col-3" label="Fecha Inicio" dateFormat="dd/mm/yy" ref="date_prospect" 
                                        v-model="entity.initial_date" />
                                    <FormCalendar :readonly="true"
                                        wrapperClass="field col-3" label="Fecha Fin" dateFormat="dd/mm/yy" ref="date_prospect" 
                                        v-model="entity.final_date" />
                                    <FormDropdownComplex
                                        :labelInOption="'id - name'"
                                        :labelInValue="'id - name'"
                                        :wrapperClass="'field col-12 md:col-3 xl:col-3'"
                                        label="Sucursal"
                                        v-model="entity.branch"
                                        :options="branches"
                                        optionLabel="name"
                                        optionValue="id"
                                    />
                                    <FormInputText
                                        ref="Article"
                                        label="Nombre del solicitante"
                                        wrapperClass="field col-12 xl:col-3"
                                        v-model="entity.name"
                                        :readonly="true"
                                        @search="(userHelper.visible = true)"
                                        :search="true"
                                    />
                                    <FormDropdownComplex
                                        :labelInOption="'id - name'"
                                        :labelInValue="'id - name'"
                                        :wrapperClass="'field col-12 md:col-1 xl:col-3'"
                                        label="Tiene OC"
                                        v-model="entity.OC"
                                        :options="OC"
                                        optionLabel="name"
                                        optionValue="id"
                                    />
                                    <FormDropdownComplex
                                        :labelInOption="'id - name'"
                                        :labelInValue="'id - name'"
                                        :wrapperClass="'field col-12 md:col-2 xl:col-3'"
                                        label="Estatus"
                                        v-model="entity.id_status"
                                        :options="id_status"
                                        optionLabel="name"
                                        optionValue="id"
                                    />
                                    <FormDropdownComplex
                                        :labelInOption="'id - name'"
                                        :labelInValue="'id - name'"
                                        :wrapperClass="'field col-12 md:col-3 xl:col-3'"
                                        label="Departamento de entrega"
                                        v-model="entity.id_department_to_deliver"
                                        :options="id_department_to_deliver"
                                        optionLabel="name"
                                        optionValue="id"
                                    />
                                    <FormDropdownComplex
                                        :labelInOption="'id - name'"
                                        :labelInValue="'id - name'"
                                        :wrapperClass="'field col-12 md:col-3 xl:col-3'"
                                        label="Tipo de Requisicion"
                                        v-model="entity.id_requisition_type"
                                        :options="id_requisition_type"
                                        optionLabel="name"
                                        optionValue="id"
                                    />
                                    
                                </div>
                            </Fieldset>
                            <Fieldset :toggleable="true" legend="Requisiciones">
                                <div class="p-fluid formgrid grid">
                                    <div class="col-12">
                                        <BasicDatatable :headers="requisitionHeaders" @supplied="supply"
                                            :rowaction="true" :authorize="true" :selectionMode="'single'" 
                                            :dataKey="'number'"  :rows="reqadmin" :supply="true" @authorized="authorize"
                                            :deleteSign="true" @signed="sign"/>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>
<script>
import FormInputText from "../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
// import DeleteDialog from "../../../components/general/DeleteDialog.vue";
// import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";

import { ShoppingRequisition } from "../../../models/compras/ShoppingRequisition";
import { ShoppingRequisitionType } from "../../../models/compras/ShoppingRequisitionType";
import { Branch } from "../../../models/general/Branch";
import { ERPUser } from "../../../models/seguridad/ERPUser";

import {
    HeaderGrid,
    //Rule,
    //validate,
    fillObject,
    //Toast,
    ErrorToast,
} from "../../../utilities/General";

import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import HelperDialog from "../../../components/general/HelperDialog.vue";
import moment from "moment";


export default {
    mixins: [Session],
    props: {
        modal: null,
        id: null
    },
    components: { HelperDialog, FormDropdownComplex, Loader,  FormInputText, FormCalendar, BasicFormToolbar, BasicDatatable},
    data(){
        return{
            shopping: new ShoppingRequisition(this.session),
            auts: [],
            entity: {
            fecha_actual: new Date(),
            id: null,
            name: null,
            brnach: null,
            id_status: null,
            id_department_to_deliver: null,
            id_requisition_type: null,
            initial_date: this.fecha_actual,
            final_date: this.fecha_actual,
            OC: null,
            },
            loading: false,
            users: [],
            branches: [],
            reqadmin: [],
            id_department_to_deliver:[],
            id_status: [],
            OC:[],
            id_requisition_type: [],

            requisitionHeaders: [
                new HeaderGrid('Estatus', 'name_status', {type: 'component', component:'Tag'}),
                new HeaderGrid('Solicitante', 'created_by', {imgUser: true}),
                new HeaderGrid('Numero de requisicion', 'id'), 
                new HeaderGrid('Tipo de Requisicion', 'requisitionTypeName'), 
                new HeaderGrid('Fecha de Requisicion', 'date_req', {type: "date"}),
                new HeaderGrid('Observacion(es)', 'observation'),
                new HeaderGrid('Departamento a Entregar', 'department'), 
                new HeaderGrid('Autoriza', 'name_authorized')
                //new HeaderGrid('P.U.', 'unit_price', { type: 'currency' })
            ],
            userHeaders: [
                new HeaderGrid('Solicitante','id'),
                new HeaderGrid('Nombre del Solicitante','username')
            ],
            userHelper: {
                visible: false,
            },
        }
    },
    computed(){

    },
    created (){
        this.entity = {
            company: null,
            branch: null,
            id: null,
            name: null,
            id_status: null,
            id_department_to_deliver: null,
            id_requisition_type: null,
            initial_date: this.entity.fecha_actual,
            final_date: this.entity.fecha_actual,
            OC: null,
        }
        
    },
    async mounted(){
        await this.refresh();
    },
    methods: {
        sign(payload){
            try {
                this.shopping = fillObject(this.shopping, payload);
                this.shopping.status = 1;
                this.shopping.name_status="Registrado";
                this.shopping.name_authorized=null;
                this.shopping.update();
                payload.status=1;
                payload.name_status="Registrado";
                payload.name_authorized=null;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        supply(payload){
            try {
                this.shopping = fillObject(this.shopping, payload);
                this.shopping.status = 3;
                this.shopping.name_status="Surtido";
                this.shopping.update();
                payload.status=3;
                payload.name_status="Surtido";
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        authorize(payload){
            try {
                this.shopping = fillObject(this.shopping, payload);
                this.shopping.status = 2;
                this.shopping.name_authorized = this.session.user;
                this.shopping.name_status="Autorizado";
                this.shopping.update();
                payload.status=2;
                payload.name_authorized=this.session.user;
                payload.name_status="Autorizado";
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async openTable(){
            this.loading = true;
            this.editInvoice = false;
            try {
                this.reqadmin = await new ShoppingRequisition(this.session).getAdmin({
                    branch_sel: this.entity.branch ?? 0,
                    initial_date: moment(this.entity.initial_date).format(),
                    final_date: moment(this.entity.final_date).format(),
                    name: this.entity.name ?? '',
                    id_status: this.entity.id_status ?? 0,
                    id_department_to_deliver: this.entity.id_department_to_deliver ?? 0,
                    id_requisition_type: this.entity.id_requisition_type ?? 0,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
            console.log(JSON.stringify(this.reqadmin[0].name_status))
            // this.requisitionHeaders = [
            //     new HeaderGrid('Estatus', 'name_status', { type: 'component', component: 'Tag'}
            //      ), 
            //     new HeaderGrid('Solicitante', 'created_by'),
            //     new HeaderGrid('Numero de requisicion', 'id'), 
            //     new HeaderGrid('Tipo de Requisicion', 'requisitionTypeName'), 
            //     new HeaderGrid('Fecha de Requisicion', 'date_req', {type: "date"}),
            //     new HeaderGrid('Observacion(es)', 'observation'),
            //     new HeaderGrid('Departamento a Entregar', 'department'), 
            //     new HeaderGrid('Autoriza', 'name_authorized')
            //     //new HeaderGrid('P.U.', 'unit_price', { type: 'currency' })
            // ];
        },
        selectUser(payload){
            this.entity.id=payload.id;
            this.entity.name=payload.username;
        },
        openNew() {
            this.entity.company = null;
            this.entity.initial_date = new Date();
            this.entity.final_date= new Date();
            this.entity.OC = null;
            this.entity.id = null;
            this.entity.name = null;
            this.entity.id_status= null;
            this.entity.id_department_to_deliver = null;
            this.entity.id_requisition_type = null;
        },
        async save() {
        },
        async refresh() {
            try{
                this.loading = true;
                //this.entities = await new ShoppingRequisition(this.session).all();
                this.id_department_to_deliver= [{id:1,name:"ALMACEN"},{id:2,name:"ADMINISTRACION"} ,{id:3,name:"LOGISTICA"}];
                this.id_status = [{id:1,name:"REGISTRADO"},{id:2,name:"AUTORIZADO"} ,{id:3,name:"SURTIDO"}];
                this.OC = [{id:1,name:"TODAS"},{id:2,name:"SI"} ,{id:3,name:"NO"}];
                this.id_requisition_type = await new ShoppingRequisitionType(this.session).all();
                this.users = await new ERPUser(this.session).all();
                this.branches = await new Branch(this.session).all();
                this.entity.branch = this.session.branch;
                //console.log(JSON.stringify(this.reqadmin))
            }catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>

<style>

.pag-tag-value{
    background-color: blueviolet;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>